import { gql, useApolloClient, useMutation } from "@apollo/client"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { NotificationContext } from "@/hooks/NotificationHook"
import NotificationPopUp from "../notifications/NotificationPopUp"
import { UserDataContext } from "@/hooks/UserDataHook"
import { MERGE_SLIDES } from "@/graphql/mutations"
import { useForm } from "@/hooks/FormHook"
import { isRequired } from "@/components/utils/helpers"
import { NotificationError } from "@/components/utils/notifications"
import * as Sentry from "@sentry/react"

const MY_PRESENTATIONS = gql`
  query myBatches {
    myBatches {
      _id
      id
      name
      mode
      batchId
      icon
      labels
      urlToken
      isFavourite
      isMyPresentation
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      creator {
        id
        firstName
        lastName
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
        phash
        lock
      }
      category {
        _id
      }
    }
  }
`

const SAVE_CART = gql`
  mutation saveCart($name: String!, $slideIds: [String!]!) {
    saveCart(name: $name, slideIds: $slideIds) {
      code
      success
      message
      presentation {
        _id
        id
        thumbUrl
        name
        batchId
        mode
        icon
        isFavourite
        sharedPresentationLinks {
          isActive
          token
          isDownloadable
          _id
        }
        creator {
          id
          firstName
          lastName
        }
        updatedAt
        accessibleByUsers {
          _id
          firstName
          lastName
        }
        accessibleByUnits {
          _id
          name
        }
        accessibleByAll
        urlToken
        labels
        slides {
          id
          slideId
          blueprintId
          thumbUrl
          name
          tags
          downloadUrl
          linksDataHeight
          linksDataWidth
          state
          isFavourite
          phash
          lock
        }
        category {
          _id
        }
      }
    }
  }
`

const NotificationContent = () => (
  <p className="cursor-pointer">
    Presentation saved successfully. Click <Link to={"/my-presentations"}>here</Link> to preview.
  </p>
)

const CartModalForm = ({ closeAfterSave, closeModal, slides }) => {
  const client = useApolloClient()
  const { open } = useContext(NotificationContext)
  const { updateSlidesInCartFunction } = useContext(UserDataContext)
  const [values, onChange] = useForm([
    {
      name: "name",
      validators: [isRequired]
    }
  ])
  const [_saveCart, { loading }] = useMutation(SAVE_CART, {
    context: { isUsingNewScApi: true }
  })
  const [mergeSlidesForCreation] = useMutation(MERGE_SLIDES, {
    context: { isUsingNewScApi: true }
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { name } = values

    const slideIds = slides.map((item) => item.blueprintId)

    const {
      data: { saveCart }
    } = await _saveCart({
      variables: { name, slideIds }
    })
    if (saveCart.success) {
      const myBatchesData = client.readQuery({ query: MY_PRESENTATIONS }) || {
        myBatches: []
      }

      client.writeQuery({
        query: MY_PRESENTATIONS,
        data: {
          myBatches: [{ ...saveCart.presentation, isMyPresentation: true }, ...myBatchesData.myBatches]
        }
      })

      closeModal()

      open({
        type: "success",
        duration: 5,
        content: (
          <NotificationPopUp>
            <NotificationContent />
          </NotificationPopUp>
        )
      })

      localStorage.setItem("cartIsSaved", `${true}`)

      await mergeSlidesForCreation({ variables: { id: saveCart.presentation.id } })

      if (closeAfterSave) {
        await updateSlidesInCartFunction([])
      }
    } else {
      Sentry.captureMessage(saveCart.message, "error")
      open({
        type: "error",
        duration: 3,
        content: <NotificationError message="Something went wrong, please try again later" />
      })
      closeModal()
    }
  }

  return (
    <div className="sc-modal-content">
      <div className="sc-modal-header">
        <h1>Name presentation</h1>
        <button className="close-modal" onClick={closeModal}>
          <svg version="1.1" viewBox="0 0 14 14" x="0px" y="0px">
            <path
              className="close-icon-fill"
              d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
              S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
              s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
            />
          </svg>
        </button>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <label>Name</label>
          <input
            className="collection-name-input"
            name="name"
            onChange={onChange}
            placeholder="Presentation name"
            type="text"
            value={values.name}
          />
          <div className="sc-modal-buttons">
            <button className="btn-gray-blue" onClick={closeModal} type="button">
              Cancel
            </button>
            <button className="btn-dark-blue" disabled={!values.name.trim() || loading} type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default CartModalForm
