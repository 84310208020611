import { useCallback, useRef, useState } from "react"
import { addSlideToPowerPoint, getSelectedSlideId, selectInsertedSlide } from "@/components/utils/powerpoint/add-in"

interface SlideTask {
  slideId: string
  getSlideDownloadUrl: (slideId: string) => Promise<{ data: { slideDownloadUrl: string } }>
  resolve: () => void
  reject: (error: any) => void
}

const useSlideQueueManager = () => {
  // Queue stored in a ref to avoid triggering re-renders on queue updates.
  const queueRef = useRef<SlideTask[]>([])
  const processingRef = useRef<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  // Internal function to download the slide and return a base64 string.
  const downloadSlideInternal = useCallback(
    async (
      slideId: string,
      getSlideDownloadUrl: (slideId: string) => Promise<{ data: { slideDownloadUrl: string } }>
    ): Promise<string> => {
      const { data } = await getSlideDownloadUrl(slideId)
      const response = await fetch(data.slideDownloadUrl)
      const blob = await response.blob()
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            const base64 = reader.result.split(",")[1] // Remove data URL prefix.
            resolve(base64)
          } else {
            reject(new Error("Failed to read blob as data URL."))
          }
        }
        reader.onerror = reject
        reader.readAsDataURL(blob)
      })
    },
    []
  )

  // Internal function to add the slide to PowerPoint.
  const addSlideToPowerPointInternal = useCallback(async (base64Slide: string, selectedSlideId: string | null) => {
    await addSlideToPowerPoint(base64Slide, selectedSlideId)
  }, [])

  // Internal function to select the newly added slide.
  const selectNewSlideInternal = useCallback(async (selectedSlideId: string | null) => {
    await selectInsertedSlide(selectedSlideId)
  }, [])

  // Process the queue using a while loop to ensure FIFO order.
  const processQueue = useCallback(async (): Promise<void> => {
    // Mark that processing has started.
    processingRef.current = true
    setIsProcessing(true)
    // Clear the previous queue error state
    setError(false)

    while (queueRef.current.length > 0) {
      const currentTask = queueRef.current.shift()
      if (!currentTask) continue
      try {
        const base64Slide = await downloadSlideInternal(currentTask.slideId, currentTask.getSlideDownloadUrl)
        const selectedSlideId = await getSelectedSlideId()
        await addSlideToPowerPointInternal(base64Slide, selectedSlideId)
        await selectNewSlideInternal(selectedSlideId)
        currentTask.resolve()
      } catch (error) {
        setError(true)
        currentTask.reject(error)
      }
    }

    // When the queue is empty, update the processing flag.
    processingRef.current = false
    setIsProcessing(false)
  }, [downloadSlideInternal, addSlideToPowerPointInternal, selectNewSlideInternal])

  const addSlide = useCallback(
    (
      slideId: string,
      getSlideDownloadUrl: (slideId: string) => Promise<{ data: { slideDownloadUrl: string } }>
    ): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        queueRef.current.push({
          slideId,
          getSlideDownloadUrl,
          resolve,
          reject
        })

        if (!processingRef.current) {
          processQueue()
        }
      })
    },
    [processQueue]
  )

  return { addSlide, isProcessing, error }
}

export default useSlideQueueManager
