import React, { Dispatch, forwardRef, SetStateAction } from "react"
import { DownloadIcon } from "@/svg/SvgIcons"

interface BatchControllerProps {
  isFull: boolean
  size: number
  currentSlide: number
  changeSlide(val: number): void
  toggleFull: (val: boolean) => void | Dispatch<SetStateAction<boolean>>
  toggleShowAllSlides(val: boolean): void
  isDownloadable: boolean
  pptx: string
  handleAddDownloadEvent: () => void
}

// eslint-disable-next-line react/display-name
const BatchController = forwardRef(
  (
    {
      isFull,
      size,
      currentSlide,
      changeSlide,
      toggleFull,
      toggleShowAllSlides,
      isDownloadable,
      pptx,
      handleAddDownloadEvent
    }: BatchControllerProps,
    ref
  ) => {
    const handleFullScreen = () => {
      toggleFull(!isFull)
    }

    const handleShowAllSlides = () => {
      toggleShowAllSlides(true)
    }

    const previousSlide = () => {
      changeSlide(currentSlide - 1)
    }

    const nextSlide = () => {
      changeSlide(currentSlide + 1)
    }

    const handleDownloadPresentation = async () => {
      const presentationUrl = pptx
      const shouldBlockNavigationRef = ref as React.MutableRefObject<boolean>
      if (presentationUrl) {
        shouldBlockNavigationRef.current = false
        window.location.href = presentationUrl
        handleAddDownloadEvent()
        shouldBlockNavigationRef.current = true
      }
    }

    return (
      <div className="viewer-controller">
        <button className="btn btn-viewer-all" onClick={handleShowAllSlides}>
          <span>
            <svg version="1.1" viewBox="0 0 24.8 17.4" x="0px" y="0px">
              <path
                d="M24.8,0H6v3H3v3H0v11.3h18.8v-3h3v-3h3V0z M17.8,16.4H1V7h16.8V16.4z M20.8,13.4h-2V6H4V4h16.8
            V13.4z M23.8,10.3h-2V3H7V1h16.8V10.3z"
              />
            </svg>
          </span>
        </button>
        <button className="btn btn-viewer-prev" disabled={currentSlide === 0} onClick={previousSlide}>
          <span>
            <svg version="1.1" viewBox="0 0 9.2 14.1" x="0px" y="0px">
              <path
                d="M8.7,14.1c-0.1,0-0.2,0-0.3-0.1L0.2,7.4C0.1,7.4,0,7.2,0,7.1s0.1-0.3,0.2-0.4l8.2-6.6
            C8.6-0.1,8.9,0,9,0.2C9.2,0.4,9.2,0.7,9,0.9L1.3,7.1L9,13.2c0.2,0.2,0.2,0.5,0.1,0.7C8.9,14.1,8.8,14.1,8.7,14.1z"
              />
            </svg>
          </span>
        </button>
        <div className="viewer-currentslide" style={{ color: "white" }}>{`${currentSlide + 1}/${size}`}</div>
        <button className="btn btn-viewer-next" disabled={currentSlide + 1 === size} onClick={nextSlide}>
          <span>
            <svg id="Layer_1" version="1.1" viewBox="0 0 9.2 14.1" x="0px" y="0px">
              <path
                d="M0.5,14.1c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.1-0.5,0.1-0.7l7.7-6.2L0.2,0.9
            C0,0.7-0.1,0.4,0.1,0.2C0.3,0,0.6-0.1,0.8,0.1L9,6.7c0.1,0.1,0.2,0.2,0.2,0.4S9.1,7.4,9,7.4L0.8,14C0.7,14.1,0.6,14.1,0.5,14.1z"
              />
            </svg>
          </span>
        </button>
        <button className="btn btn-viewer-slideshow" onClick={handleFullScreen}>
          {!isFull ? (
            <span>
              <svg version="1.1" viewBox="0 0 19 17.7" x="0px" y="0px">
                <path
                  d="M19,1.5h-9V0H9v1.5H0v1h1.3V14H9v2.6H6.5v1h6v-1H10V14h7.7V2.5H19V1.5z M16.7,13H2.3V2.5h14.4
                V13z"
                />
                <polygon points="8,5.3 8,10.6 12,7.9 	" />
              </svg>
            </span>
          ) : (
            <span className="slideshow-icon-close">
              <svg version="1.1" viewBox="0 0 16.7 16.7" x="0px" y="0px">
                <polygon points="4.5,4.5 0,4.5 0,5.5 5.5,5.5 5.5,0 4.5,0 	" />
                <polygon points="12.2,4.5 12.2,0 11.2,0 11.2,5.5 16.7,5.5 16.7,4.5 	" />
                <polygon points="11.2,16.7 12.2,16.7 12.2,12.2 16.7,12.2 16.7,11.2 11.2,11.2 	" />
                <polygon points="0,12.2 4.5,12.2 4.5,16.7 5.5,16.7 5.5,11.2 0,11.2 	" />
              </svg>
            </span>
          )}
        </button>
        <div className="relative group">
          {!isDownloadable && (
            <span
              className="absolute invisible group-hover:visible bottom-[150%] left-1/2 transform -translate-x-1/2 text-[14px] text-white bg-[#0f2642e6] w-[200px] p-[10px] rounded-md
       before:content-[''] before:absolute before:top-full before:left-1/2 before:transform before:-translate-x-1/2 before:border-[5px] before:border-transparent before:border-t-[#0f2642e6]"
            >
              Downloading is disabled by the author
            </span>
          )}
          <button className="btn btn-viewer-slideshow" data-testid="download-from-slide-show" disabled={!isDownloadable} onClick={handleDownloadPresentation}>
            <DownloadIcon />
          </button>
        </div>
      </div>
    )
  }
)

export default BatchController
