import React, { useContext } from "react"
import { FaChevronRight } from "react-icons/fa6"
import { UserDataContext } from "../../hooks/UserDataHook"

const CustomDisclosure = ({ open, presentationsType, handleExpand, children }) => {
  const {
    user: { cart }
  } = useContext(UserDataContext)

  return (
    <div
      className={`w-full flex justify-center mobile-xs:px-[24px] ${
        cart ? "tablet-xl:px-[60px]" : "tablet-sm:px-[60px]"
      }`}
    >
      <div className="w-full desktop-big:w-[1812px]">
        <button
          className="w-fit text-sm flex items-center py-[7px] px-[13px] hover:bg-sc-hover-dark gap-[12px] rounded-sm"
          data-testid={`toggle-${presentationsType}-disclosure`}
          onClick={handleExpand}
        >
          <FaChevronRight
            className={`transition-transform duration-300 ease-in-out transform ${open ? "rotate-90" : ""}`}
          />
          <span className="capitalize">{presentationsType}</span>
        </button>
        {open && (
          <div className="mt-[25px]" data-testid={`expanded-${presentationsType}-container`}>
            {children}
          </div>
        )}
      </div>
    </div>
  )
}
export default CustomDisclosure
