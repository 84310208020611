export async function getSelectedSlideId() {
  let selectedSlideId = null
  await window.PowerPoint.run(async function (context) {
    context.presentation.slides.load()
    await context.sync()
    if (context.presentation.slides.items.length) {
      selectedSlideId = await new OfficeExtension.Promise(function (resolve, reject) {
        Office.context.document.getSelectedDataAsync(Office.CoercionType.SlideRange, function (asyncResult) {
          try {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              reject(console.error(asyncResult.error.message))
            } else {
              resolve(asyncResult.value.slides[0].id)
            }
          } catch (error) {
            reject(console.log("failed to get selectedSlideId"), error)
          }
        })
      })
    }
  })
  return selectedSlideId
}

export function selectInsertedSlide(id) {
  const index = id ? Office.Index.Next : Office.Index.First
  return new Promise((resolve, reject) => {
    Office.context.document.goToByIdAsync(index, Office.GoToType.Index, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        reject(asyncResult.error.message)
      } else {
        resolve()
      }
    })
  })
}

export async function deleteOutdatedSlide(index) {
  await window.PowerPoint.run(async function (context) {
    // The slide index is zero-based.
    const slide = context.presentation.slides.getItemAt(index)
    slide.delete()
    await context.sync()
  })
}

export async function insertSlideInPowerPoint(slideDownloadUrl, targetSlideId) {
  const response = await fetch(slideDownloadUrl)
  const blob = await response.blob()
  const reader = new FileReader()

  const base64String = await new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result.split(",")[1]) // Remove the data URL prefix to get only the base64 string
    }
    reader.onerror = (error) => {
      reject(error)
    }
    reader.readAsDataURL(blob)
  })

  await window.PowerPoint.run(async function (context) {
    context.presentation.insertSlidesFromBase64(base64String, {
      formatting: "KeepSourceFormatting",
      targetSlideId: targetSlideId || null
    })
    await context.sync()
  })
}

export async function getUpdatedSlides(fetchUpdatedSlides) {
  let updatedSlides
  await window.PowerPoint.run(async function (context) {
    const slides = context.presentation.slides
    slides.load("tags/key, tags/value")
    await context.sync()
    const taggedSlides = slides.items.reduce((acc, slide, index) => {
      const idTag = slide.tags.items.find((tag) => tag.key === "ID")
      const phashTag = slide.tags.items.find((tag) => tag.key === "PHASH")
      if (idTag && phashTag) {
        acc.push({ id: idTag.value, phash: phashTag.value, index })
      }
      return acc
    }, [])
    const uniqueSlideIds = Array.from(new Set(taggedSlides.map((s) => s.id)))
    const { data } = await fetchUpdatedSlides({
      variables: { slideIds: uniqueSlideIds }
    })
    updatedSlides = taggedSlides.reduce((acc, taggedSlide) => {
      const updatedSlide = data.updatedSlides.find((updatedSlide) => updatedSlide.id === taggedSlide.id)
      if (updatedSlide && taggedSlide.phash !== updatedSlide.phash) {
        acc.push({ ...updatedSlide, index: taggedSlide.index })
      }
      return acc
    }, [])
  })
  return updatedSlides
}

export async function addSlideToPowerPoint(base64Slide, selectedSlideId) {
  await window.PowerPoint.run(async function (context) {
    context.presentation.insertSlidesFromBase64(base64Slide, {
      formatting: "KeepSourceFormatting",
      targetSlideId: selectedSlideId ? selectedSlideId + "#" : null
    })
    await context.sync()
  })
}
