import { useState, useRef, useEffect } from "react"

function UsersProfileDropDown({
  title,
  filter,
  setFilter,
  options,
  counts,
  type,
  openModal,
  invitationSent,
  handleResendInvitation,
  nameInModal,
  userId,
  inviteeId,
  isDisabled,
  invitationLink
}) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    function handleOutsideClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownIsOpen(false)
      }
    }

    window.addEventListener("mousedown", handleOutsideClick)

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick)
    }
  }, [])

  return (
    <div className="relative" ref={dropdownRef}>
      {type === "teamMember" ? (
        <>
          <button
            className={`relative z-0 flex items-center border border-solid border-[#343a40] rounded leading-normal text-[13px] text-[#212529] font-medium ${
              dropdownIsOpen && "bg-[#343a40] text-white"
            } hover:bg-[#343a40] hover:text-white py-[.15rem] px-3 user-profile-btn`}
            data-testid="team-member-context-menu-button"
            disabled={isDisabled}
            onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
          >
            <span className="mb-[2px]">{title}</span>
          </button>
          {dropdownIsOpen && (
            <div className="absolute right-0 mt-[.125rem] min-w-[160px] z-10 text-left rounded border border-solid border-[#ced4da] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-[0.5rem]" data-testid="team-member-context-menu">
                {inviteeId && (
                  <>
                    <button
                      className="text-xs px-6 py-1 block hover:bg-[#f8f9fa] hover:text-[#0080ff] cursor-pointer"
                      disabled={invitationSent}
                      onClick={handleResendInvitation}
                      type="button"
                    >
                      Resend invitation
                    </button>
                  </>
                )}
                {userId && (
                  <span
                    className="text-xs px-6 py-1 block hover:bg-[#f8f9fa] hover:text-[#0080ff] cursor-pointer"
                    href="#"
                    onClick={() => {
                      setDropdownIsOpen(false)
                      openModal({
                        content: "changeMemberRole",
                        data: { userId }
                      })
                    }}
                  >
                    Change role
                  </span>
                )}
                <div className="border border-solid border-[#e9ecef] my-2" />
                {/* This needs to be removed for now untill we doulbe check if there are no security concerns */}
                {!userId && import.meta.env.DEV && (
                  <>
                    <span
                      className="text-xs px-6 py-1 block hover:bg-[#f8f9fa] hover:text-[#0080ff] cursor-pointer"
                      data-testid="show-invitation-link"
                      href="#"
                      onClick={() => {
                        setDropdownIsOpen(false)
                        openModal({
                          content: "showInvitationLink",
                          data: { link: invitationLink }
                        })
                      }}
                    >
                      Show invitation link
                    </span>
                    <div className="border border-solid border-[#e9ecef] my-2" />
                  </>
                )}
                <span
                  className="text-xs px-6 py-1 block text-[#dc3545] hover:bg-[#f8f9fa] cursor-pointer"
                  onClick={() => {
                    setDropdownIsOpen(false)
                    openModal({
                      content: "removeUserFromTeam",
                      data: { nameInModal, userId, inviteeId }
                    })
                  }}
                >
                  {userId ? "Remove from team" : "Remove invitation"}
                </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <button
            className={`relative z-0 flex items-center border border-solid border-[#f8f9fa] rounded leading-normal text-[13px] text-[#212529] font-medium ${
              dropdownIsOpen && "bg-[#e2e6ea]"
            } hover:bg-[#e2e6ea] py-1.5 px-3 user-profile-btn`}
            data-testid={`users-filter-dropdown-${title.toLowerCase()}`}
            onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
          >
            <span>{title}</span>
          </button>
          {dropdownIsOpen && (
            <div className="absolute md:right-0 mt-[.125rem] min-w-[160px] z-10 text-left rounded border border-solid border-[#ced4da] shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                {title === "Status" && (
                  <h6 className="text-sm text-[#6c757d] mb-0 px-4 py-2 whitespace-nowrap">
                    {title === "Status" ? "Filter by invitation status" : `Filter by ${title.toLowerCase()}`}
                  </h6>
                )}
                {options.map((option, index) => (
                  <button
                    className={`w-full py-1 px-6 text-[#212529] text-left text-xs ${
                      filter === option
                        ? "active hover:text-[#0080ff] font-bold dropdown-icon"
                        : "font-normal hover:bg-[#f8f9fa] hover:text-[#0080ff]"
                    }`}
                    data-testid="dropdown-item"
                    key={index}
                    onClick={(e) => {
                      setDropdownIsOpen(false)
                      setFilter(e.target.value)
                    }}
                    type="button"
                    value={option}
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                    {title !== "Role" && <small> ({counts[option]})</small>}
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default UsersProfileDropDown
