import { gql, useLazyQuery, useMutation } from "@apollo/client"
import React, { Fragment, useContext, useMemo, useRef, useState } from "react"
import { MainModalContext } from "@/hooks/MainModalHook"
import { Batch } from "@/graphql/types/queries/batch"
import { CategoryContext } from "@/context/CategoryContext"
import Category from "@/components/modals/changeOrder/ChangeOrderCategory"
import { BiSearch } from "react-icons/bi"
import { filterCategoryByPresentationNameQuery } from "@/utils/helpers"
import { Link } from "react-router-dom"
import { NotificationContext } from "@/hooks/NotificationHook"
import slugify from "slug/slug"
import { PlusIcon } from "@/svg/SvgIcons"
import { Menu, Transition } from "@headlessui/react"
import { Ellipsis } from "@/components/utils/tooltip"
import AddPresentationForm from "@/components/modals/moveOrCopySlides/AddPresentationForm"

const MOVE_SLIDES_BETWEEN_PRESENTATIONS = gql`
  mutation moveSlidesBetweenPresentations($slideIds: [String!]!, $targetPresentationId: String!) {
    moveSlidesBetweenPresentations(slideIds: $slideIds, targetPresentationId: $targetPresentationId) {
      code
      success
      message
    }
  }
`

const COPY_SLIDES_TO_PRESENTATION = gql`
  mutation copySlidesToPresentation($slideIds: [String!]!, $targetPresentationId: String!) {
    copySlidesToPresentation(slideIds: $slideIds, targetPresentationId: $targetPresentationId) {
      code
      success
      message
    }
  }
`

const GET_PRESENTATIONS = gql`
  query presentations($ids: [String!]!) {
    presentations(ids: $ids) {
      _id
      id
      thumbUrl
      name
      batchId
      icon
      isFavourite
      state
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
      }
      category {
        _id
      }
    }
  }
`

interface MoveOrCopySlidesProps {
  blueprintIds: string[]
  presentationIds: string[]
  isCopyToModal: boolean
  closeSelectMode?: () => void
}

interface PresentationListItem extends Batch {
  selected: boolean
  current: boolean
}

interface SelectedPresentation {
  id: string
  name: string
  urlToken: string
  isMyPresentation: boolean
}

interface AddPresentationInput {
  categoryId: string
  type: "collection" | "presentation"
  value: string
  isSubCategory: boolean
}

type AddPresentationContainerRef = Omit<AddPresentationInput, "value"> & {
  target: HTMLDivElement | null
}

const MoveOrCopySlides = ({ blueprintIds, presentationIds, isCopyToModal, closeSelectMode }: MoveOrCopySlidesProps) => {
  const { closeModal } = useContext(MainModalContext)
  const { categories } = useContext(CategoryContext)
  const [selectedPresentation, setSelectedPresentation] = useState<SelectedPresentation | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [addPresentationInput, setAddPresentationInput] = useState<AddPresentationInput | null>(null)
  const { open } = useContext(NotificationContext)
  const [getPresentationsData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATIONS, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const slides = presentation.slides
      if (!slides?.length) return
      if (presentation.state === "merged") {
        stopPresentationDataPolling()
      }
    }
  })
  const [moveSlidesBetweenPresentations] = useMutation(MOVE_SLIDES_BETWEEN_PRESENTATIONS, {
    context: { isUsingNewScApi: true }
  })
  const [copySlidesToPresentation] = useMutation(COPY_SLIDES_TO_PRESENTATION, {
    context: { isUsingNewScApi: true }
  })

  const filteredCategories = useMemo(() => {
    const searchQuery = searchInput.toLowerCase() // Pre-compute lowercase search filter
    return categories.reduce((filtered: (typeof Category)[], category: typeof Category) => {
      const filteredCategory = filterCategoryByPresentationNameQuery(category, searchQuery)
      if (searchQuery.length) {
        if (filteredCategory.fullPresentationsLength) {
          filtered.push(filteredCategory)
        }
      } else {
        filtered.push(filteredCategory)
      }

      return filtered
    }, [])
  }, [searchInput, categories])

  const addPresentationContainerRefs = useRef<AddPresentationContainerRef[]>([])

  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const onPresentationClick = ({ id, name, urlToken, isMyPresentation }: PresentationListItem) => {
    setSelectedPresentation({
      id,
      name,
      urlToken,
      isMyPresentation
    })
  }

  const scrollToElement = (targetContainer: AddPresentationContainerRef) => {
    const scrollableContainer = scrollableContainerRef.current

    if (targetContainer.target && scrollableContainer) {
      // Calculate the position of the element relative to the container
      const elementOffsetTop = targetContainer.target.offsetTop

      // Calculate the scroll position considering the sticky header
      const scrollToPosition = elementOffsetTop - (targetContainer.isSubCategory ? 200 : 100)

      // Smooth scroll the container to the correct position
      scrollableContainer.scrollTo({
        top: scrollToPosition,
        behavior: "smooth"
      })
    }
  }
  const addPresentationClick = (categoryId: string, type: "collection" | "presentation", isSubCategory: boolean) => {
    setAddPresentationInput({ categoryId, type, isSubCategory, value: "" })

    const targetContainer = addPresentationContainerRefs.current.find(
      (container) => container.categoryId === categoryId && container.type === type
    )
    if (targetContainer) scrollToElement(targetContainer)
  }

  const handleSubmit = async () => {
    if (!selectedPresentation) return
    setIsProcessing(true)
    const presentationIdsToRefetch = [selectedPresentation.id]
    let moveOrCopyMutation
    if (isCopyToModal) {
      moveOrCopyMutation = copySlidesToPresentation
    } else {
      moveOrCopyMutation = moveSlidesBetweenPresentations
      presentationIdsToRefetch.push(...presentationIds)
    }
    await moveOrCopyMutation({
      variables: {
        slideIds: blueprintIds,
        targetPresentationId: selectedPresentation.id
      },
      onCompleted: async (response) => {
        const responseName = isCopyToModal ? "copySlidesToPresentation" : "moveSlidesBetweenPresentations"
        if (response[responseName].success) {
          open({
            type: "success",
            duration: 4,
            content: (
              <div className="profile-notif-content">
                <div className="profile-notif-content-ico">
                  <svg version="1.1" viewBox="-470 271 19 19" x="0px" y="0px">
                    <path
                      d="M-460.5,271c-5.2,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5s9.5-4.3,9.5-9.5S-455.3,271-460.5,271z
              M-456.3,278l-4.9,5.9c-0.1,0.2-0.4,0.3-0.6,0.3c-0.2,0-0.4-0.1-0.6-0.3l-2.4-3c-0.2-0.2-0.2-0.7,0.2-1c0.3-0.2,0.8-0.2,1,0.1
              l1.9,2.3l4.3-5.2c0.3-0.3,0.7-0.4,1.1-0.1C-456,277.2-456,277.7-456.3,278z"
                      fill={"#3390FF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">
                  <p>
                    Slides {isCopyToModal ? "copied" : "moved"} successfully. Click{" "}
                    <Link
                      to={
                        selectedPresentation.isMyPresentation
                          ? `/my-presentations/${selectedPresentation.urlToken}/${slugify(selectedPresentation.name)}`
                          : `/library/${selectedPresentation.urlToken}/${slugify(selectedPresentation.name)}`
                      }
                    >
                      here
                    </Link>{" "}
                    to preview.
                  </p>
                </div>
              </div>
            )
          })
        } else {
          open({
            type: "error",
            duration: 4,
            content: (
              <div className="profile-notif-content cursor-default">
                <div className="profile-notif-content-ico">
                  <svg id="Layer_1" version="1.1" viewBox="0 0 38.3 38.3" x="0px" y="0px">
                    <circle cx="19.2" cy="19.2" fill={"#ED1E79"} r="19.2" />
                    <path
                      d="M27.4,28.1c-0.2,0-0.4-0.1-0.5-0.2L10.4,11.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l16.4,16.4
          c0.3,0.3,0.3,0.8,0,1.1C27.8,28.1,27.6,28.1,27.4,28.1z"
                      fill={"#FFFFFF"}
                    />
                    <path
                      d="M10.9,28.1c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l16.4-16.4c0.3-0.3,0.8-0.3,1.1,0
          s0.3,0.8,0,1.1L11.5,27.9C11.3,28.1,11.1,28.1,10.9,28.1z"
                      fill={"#FFFFFF"}
                    />
                  </svg>
                </div>
                <div className="profile-notif-content-text">{response[responseName].message}</div>
              </div>
            )
          })
        }
      }
    })
    await getPresentationsData({ variables: { ids: presentationIdsToRefetch } })
    setIsProcessing(false)
    closeModal()

    if (closeSelectMode) closeSelectMode()
  }

  const renderPresentationListItem = (presentation: PresentationListItem) => (
    <li
      className={`px-[20px] justify-between gap-[20px] border-b border-[#e4e8ea] flex items-center h-[52px] cursor-pointer group ${
        presentation.selected ? "bg-sc-hover-dark" : "bg-white"
      }`}
      data-testid="presentation-list-item"
      key={presentation.id}
      onClick={() => onPresentationClick(presentation)}
    >
      <div className="flex gap-[20px]">
        <div
          className={`flex justify-center w-[50px] ${
            presentation.type === "presentation" ? "h-[28.95px] bg-sc-line" : ""
          }`}
        >
          <img
            alt={presentation.icon}
            className={presentation.type === "collection" ? "w-[24px]" : ""}
            src={
              presentation.type === "collection"
                ? `/icons/collectionIcons/regular/${presentation.icon}.svg`
                : presentation.thumbUrl?.replace("{width}", "100")
            }
          />
        </div>
        <span className="line-clamp-1 group-hover:text-[#3390ff] !w-[350px] h-[28px]">{presentation.name}</span>
      </div>
      <div className={"text-sc-light-font text-sm flex justify-between gap-[10px] w-[166px]"}>
        <span
          className={`${
            presentation.current ? "visible" : "invisible"
          } w-[73px] bg-sc-hover-light px-[10px] py-[1px] rounded-[20px] text-sc-light-font text-sm`}
        >
          Current
        </span>
        <div className="flex gap-[5px]">
          <Ellipsis length={5} text={presentation.slides.length} tooltip />
          <span>slide{presentation.slides.length !== 1 ? "s" : ""}</span>
        </div>
      </div>
    </li>
  )

  const renderCategory = (category: typeof Category) => (
    <li className={`${category.isSubCategory ? "pl-[10px] pb-[10px]" : ""}`} key={category._id}>
      <div
        className={`group bg-white sticky ${
          category.isSubCategory ? "top-[44px] !text-[15px]" : "z-10 -top-[1px] !px-0"
        }`}
      >
        <div className="flex justify-between items-center ">
          <h2 className="!py-[10px] !px-0">{category.name}</h2>
          {category.isSubCategory ? (
            <button
              className="invisible group-hover:visible flex items-center justify-center w-[24px] h-[24px] hover:text-[#3390ff]"
              onClick={() => addPresentationClick(category._id, category.type, true)}
            >
              <PlusIcon />
            </button>
          ) : (
            <Menu as="div" className="flex relative">
              {({ open }) => {
                return (
                  <>
                    <Menu.Button
                      className={`${
                        open ? "visible" : "invisible"
                      } group-hover:visible flex items-center justify-center w-[24px] h-[24px] hover:text-[#3390ff]`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PlusIcon />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        className="absolute top-[40px] right-0 z-20 w-56 text-sm rounded-md bg-white shadow-lg border ring-gray-300 focus:outline-none p-1"
                        id="contextMenuItems"
                      >
                        <Menu.Item>
                          <span
                            className="cursor-pointer block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100"
                            onClick={() => addPresentationClick(category._id, "collection", false)}
                          >
                            Add Collection
                          </span>
                        </Menu.Item>
                        <Menu.Item>
                          <span
                            className="cursor-pointer block px-4 py-2 text-xs text-gray-500 hover:bg-gray-100"
                            onClick={() => addPresentationClick(category._id, "presentation", false)}
                          >
                            Add Presentation
                          </span>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </>
                )
              }}
            </Menu>
          )}
        </div>
      </div>
      <div
        ref={(el) => {
          if (
            !addPresentationContainerRefs.current.find(
              (ref) => ref.categoryId === category._id && ref.type === "collection"
            )
          ) {
            addPresentationContainerRefs.current.push({
              categoryId: category._id,
              type: "collection",
              isSubCategory: category.isSubCategory,
              target: el
            })
          }
        }}
      >
        {addPresentationInput &&
          addPresentationInput.categoryId === category._id &&
          addPresentationInput.type === "collection" && (
            <AddPresentationForm input={addPresentationInput} setInput={setAddPresentationInput} />
          )}
        {!!category.collections.length && (
          <ul>
            {category.collections.map((collection: PresentationListItem) =>
              renderPresentationListItem({
                ...collection,
                selected: selectedPresentation?.id === collection.id,
                current: presentationIds.includes(collection.id)
              })
            )}
          </ul>
        )}
      </div>
      {!!category.subCategories?.length && (
        <ul>
          {category.subCategories
            .filter((sub) => sub.type === "collection")
            .map((subCategory: typeof Category) => renderCategory({ ...subCategory, isSubCategory: true }))}
        </ul>
      )}
      <div
        ref={(el) => {
          if (
            !addPresentationContainerRefs.current.find(
              (ref) => ref.categoryId === category._id && ref.type === "presentation"
            )
          ) {
            addPresentationContainerRefs.current.push({
              categoryId: category._id,
              type: "presentation",
              isSubCategory: category.isSubCategory,
              target: el
            })
          }
        }}
      >
        {addPresentationInput &&
          addPresentationInput.categoryId === category._id &&
          addPresentationInput.type === "presentation" && (
            <AddPresentationForm input={addPresentationInput} setInput={setAddPresentationInput} />
          )}
        {!!category.presentations.length && (
          <ul>
            {category.presentations.map((presentation: PresentationListItem) =>
              renderPresentationListItem({
                ...presentation,
                selected: selectedPresentation?.id === presentation.id,
                current: presentationIds.includes(presentation.id)
              })
            )}
          </ul>
        )}
      </div>
      {!!category.subCategories?.length && (
        <ul>
          {category.subCategories
            .filter((sub) => sub.type === "presentation")
            .map((subCategory: typeof Category) => renderCategory({ ...subCategory, isSubCategory: true }))}
        </ul>
      )}
    </li>
  )

  return (
    <div className="categories-manager" data-testid="move-slides">
      <div className="w-full shadow-[0_2px_15px_0_#89898933] !z-20">
        <div className="w-[570px] flex justify-between">
          <h1>{isCopyToModal ? "Copy" : "Move"} to</h1>
          <div className="flex items-center justify-end gap-2 col-span-5 text-right">
            <div className="relative rounded flex min-w-[250px]">
              <div className="flex items-center text-base py-1.5 px-3 pointer-events-none bg-[#e9ecef] rounded-l border-r-0 border border-solid border-[#ced4da]">
                <BiSearch />
              </div>
              <input
                className="w-full py-1.5 px-3 leading-normal sm:text-base rounded rounded-bl-none rounded-tl-none focus:ring-[#80bdff] focus:border-[#80bdff]"
                id="inlineFormInputGroup"
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Search..."
                type="text"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="!w-[700px] h-[500px] overflow-y-auto overflow-x-hidden px-[20px] pb-[10px] border-b border-[#dbe0e3] scrollbar-webkit"
        ref={scrollableContainerRef}
      >
        <ul data-testid="slide-moveTo-list">
          {filteredCategories.map((category: typeof Category) => renderCategory(category))}
        </ul>
      </div>
      <div className="my-[17px]">
        <button
          className={`btn text-[13px] w-[120px] h-[28px] text-white border border-transparent rounded-14
                ${selectedPresentation ? "bg-sc-blue hover:bg-[#0080ea] duration-300" : "bg-gray-400"}
              `}
          data-testid="move-or-copy-button"
          disabled={!selectedPresentation}
          onClick={handleSubmit}
        >
          {isProcessing ? (
            <span>
              <span>{isCopyToModal ? "Copying" : "Moving"}</span>
              <span className="saving">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </span>
            </span>
          ) : isCopyToModal ? (
            "Copy"
          ) : (
            "Move"
          )}
        </button>
      </div>
    </div>
  )
}

export default MoveOrCopySlides
