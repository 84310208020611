import { useState, useCallback } from "react"

const useSlideLoading = () => {
  const [loadingIds, setLoadingIds] = useState<Set<string>>(new Set())

  const setAllSlidesLoading = useCallback((slideIds: string[], isLoading: boolean) => {
    setLoadingIds((prev) => {
      const newSet = new Set(prev)
      slideIds.forEach((id) => {
        isLoading ? newSet.add(id) : newSet.delete(id)
      })
      return newSet
    })
  }, [])

  const setSlideLoading = useCallback((slideId: string, isLoading: boolean) => {
    setLoadingIds((prev) => {
      const newSet = new Set(prev)
      isLoading ? newSet.add(slideId) : newSet.delete(slideId)
      return newSet
    })
  }, [])

  return { loadingIds, setAllSlidesLoading, setSlideLoading }
}

export default useSlideLoading
