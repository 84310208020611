import { useMutation, useQuery, gql } from "@apollo/client"
import { useContext, useEffect, useRef, useState } from "react"
import { usePrevious } from "./Previous"
import { emailRegex, delimiterRegex } from "../components/utils/helpers"
import { UserDataContext } from "@/hooks/UserDataHook"

export const ADD_MEMBER_TO_ORGANIZATION = gql`
  mutation addMemberToOrganization($emails: [String!]!) {
    addMemberToOrganization(emails: $emails) {
      code
      success
      message
      previewUrls
    }
  }
`

const ORGANIZATION_USERS_DATA = gql`
  query organization($_id: String!) {
    organization(_id: $_id) {
      users {
        email
      }
    }
  }
`
const ORGANIZATION_USERS_AND_INVITEES_DATA = gql`
  query organization($_id: String!) {
    organization(_id: $_id) {
      users {
        _id
        id
        firstName
        lastName
        email
        role
      }
      invitees {
        id
        email
        invitationLink
      }
    }
  }
`

export default function useEmailInputs() {
  const initialInputValues = [{ value: "", validation: true }]
  const {
    user: { group }
  } = useContext(UserDataContext)

  const { data } = useQuery(ORGANIZATION_USERS_DATA, {
    variables: { _id: group._id },
    context: { isUsingNewScApi: true }
  })

  const [sendInvitations, { loading }] = useMutation(ADD_MEMBER_TO_ORGANIZATION, {
    context: { isUsingNewScApi: true },
    refetchQueries: [
      { query: ORGANIZATION_USERS_AND_INVITEES_DATA, context: { isUsingNewScApi: true }, variables: { _id: group._id } }
    ]
  })

  const [isMultiple, setIsMultiple] = useState(false)

  const [singleInputs, setSingleInputs] = useState(initialInputValues)

  const [multiInput, setMultiInput] = useState("")
  const [multiInputValidation, setMultiInputValidation] = useState(false)

  const singleInputsRefs = useRef([])
  const prevSingleInputs = usePrevious(singleInputs)

  const [emailsSent, setEmailsSent] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (prevSingleInputs && prevSingleInputs.length < singleInputs.length) {
      singleInputsRefs.current[singleInputs.length - 1].focus()
    }
  }, [singleInputs, prevSingleInputs])

  const handleInputAdd = () => {
    setSingleInputs([...singleInputs, { value: "", validation: true }])
  }

  const handleInputRemove = (index) => {
    setSingleInputs(singleInputs.slice(0, index).concat(singleInputs.slice(index + 1)))
  }

  const handleSingleInputChange = (value, index) => {
    setSingleInputs((prevSingleInputs) =>
      prevSingleInputs.map((item, _index) =>
        _index === index
          ? {
              value,
              validation: item.validation || emailRegex.test(value)
            }
          : item
      )
    )
  }

  const validateSingleInputChange = (index) => {
    const updatedSingleInputs = [...singleInputs]
    updatedSingleInputs[index] = {
      ...updatedSingleInputs[index],
      validation: !updatedSingleInputs[index].value || emailRegex.test(updatedSingleInputs[index].value)
    }
    setSingleInputs(updatedSingleInputs)
  }

  const handleEnterPress = (index) => {
    index === singleInputs.length - 1 ? handleInputAdd() : singleInputsRefs.current[index + 1].focus()
  }

  const handleMultiInputChange = (e) => {
    setMultiInput(e.target.value)
    const multiInputEmails = e.target.value.split(delimiterRegex).filter((email) => email !== "")
    setMultiInputValidation(
      multiInputEmails.length > 0 && multiInputEmails.reduce((acc, cur) => acc && emailRegex.test(cur), true)
    )
  }

  const handleSwitchToMulti = () => {
    setMultiInput(
      singleInputs
        .filter((item) => !!item.value)
        .map((item) => item.value)
        .join(",\n")
    )
    setSingleInputs(singleInputs.filter((item, index) => !!item.value || index === 0))
    setIsMultiple(true)
    setMultiInputValidation(singleInputs.reduce((acc, cur) => acc && (!cur.value || cur.validation), true))
  }

  const handleSwitchToSingle = () => {
    setMultiInput("")
    setMultiInputValidation(false)
    setIsMultiple(false)
  }

  const handleSingleSubmit = async () => {
    let updatedSingleInputs = singleInputs
      .filter((item, index) => !!item.value || index === 0)
      .map((item) => ({ value: item.value.trim(), validation: emailRegex.test(item.value) }))
    if (!updatedSingleInputs.reduce((acc, cur) => acc && cur.validation, true)) {
      setSingleInputs(updatedSingleInputs)
      return
    }
    const previouslySentEmails = {}
    if (data && data.users) {
      for (const user of data.users) {
        previouslySentEmails[user.email] = true
      }
    }
    updatedSingleInputs = updatedSingleInputs.filter((item) => !(item.value in previouslySentEmails))
    if (updatedSingleInputs.length === 0) {
      setEmailsSent([])
      return
    }
    try {
      const sendInvitationResult = await sendInvitations({
        variables: { emails: updatedSingleInputs.map((input) => input.value) }
      })

      if (sendInvitationResult?.data?.addMemberToOrganization?.success) {
        setEmailsSent(updatedSingleInputs.map((item) => item.value))
      } else {
        setError(true)
      }
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  const handleMultiSubmit = async () => {
    if (!multiInputValidation) return
    let emails = multiInput
      .split(delimiterRegex)
      .filter((email) => email !== "")
      .map((email) => email.trim())
    if (emails.length === 0) {
      setMultiInputValidation(false)
      return
    }
    const previouslySentEmails = {}
    if (data && data.users) {
      for (const user of data.users) {
        previouslySentEmails[user.email] = true
      }
    }
    emails = emails.filter((item) => !(item.value in previouslySentEmails))
    if (emails.length === 0) {
      setEmailsSent([])
      return
    }
    try {
      const sendInvitationResult = await sendInvitations({ variables: { emails } })

      if (sendInvitationResult?.data?.addMemberToOrganization?.success) {
        setEmailsSent(emails)
      } else {
        setError(true)
      }
    } catch (e) {
      setError(true)
    }
  }

  return {
    isMultiple,
    singleInputs,
    singleInputsRefs,
    multiInput,
    multiInputValidation,
    handleInputAdd,
    handleInputRemove,
    handleSingleInputChange,
    validateSingleInputChange,
    handleEnterPress,
    handleMultiInputChange,
    handleSwitchToMulti,
    handleSwitchToSingle,
    handleSingleSubmit,
    handleMultiSubmit,
    loading,
    error,
    setError,
    emailsSent
  }
}
