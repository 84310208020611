import { useMemo } from "react"
import CollectionSection from "@/components/category/CollectionSection"
import CategoryMenu from "@/components/category/CategoryMenu"
import { useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { MenuIconVertical } from "@/svg/SvgIcons"

const SubCategory = ({
  category,
  subCategory,
  typeName,
  Icon,
  batchesName,
  isEditModeActive,
  active,
  setOpenedBatch,
  openedBatch,
  relevantBatches,
  index,
  activeSection
}) => {
  const requiresExtraTopMargin = relevantBatches.length || isEditModeActive || index !== 0

  const { attributes, listeners, setNodeRef, transform } = useSortable({
    id: `_${subCategory._id}`,
    data: subCategory,
    disabled: !isEditModeActive,
    strategy: verticalListSortingStrategy
  })

  const isActiveDrag = useMemo(() => activeSection && activeSection._id === subCategory._id, [activeSection])

  const style = {
    position: activeSection ? "relative" : undefined,
    zIndex: 1,
    transform: CSS.Translate.toString(transform),
    transition: activeSection ? "transform 200ms ease" : "none"
  }

  return (
    <div data-testid="section-container">
      <div
        className={`${requiresExtraTopMargin ? "mt-[25px]" : ""} group`}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
      >
        <div className={`${requiresExtraTopMargin ? "pt-[18px]" : ""} flex w-fit items-center`}>
          <h3
            className={`text-base font-semibold leading-none ${isActiveDrag ? "text-[#D1D5DB]" : " text-sc-text-dark"}`}
          >
            {subCategory.name}
          </h3>
          {isEditModeActive && (
            <CategoryMenu
              buttonStyles={{ width: "20px", paddingInline: "5px" }}
              categoryName={subCategory.name}
              deleteModalContext={
                <span>
                  Are you sure you want to remove <span className="text-scblue">{subCategory.name}&nbsp;</span>
                  from your&nbsp;SlideCamp?
                </span>
              }
              deleteModalTitle="Delete Section"
              icon={<MenuIconVertical height={12} viewBox={"0 0 3 12"} width={3} />}
              id={category._id}
              order={category.order}
              renameModalTitle="Rename Section"
              subCategory={subCategory}
            />
          )}
        </div>
      </div>
      <div className={"flex flex-col gap-[25px]"} style={{ display: activeSection ? "none" : "flex" }}>
        {/*/!*{category?.name !== "Team Presentations" && category?.name !== "Opportunities and Packages" && (*!/*/}
        <CollectionSection
          active={active}
          activeSection={activeSection}
          batchesName={batchesName}
          category={category}
          Icon={Icon}
          id={subCategory._id}
          isEditModeActive={isEditModeActive}
          isSection={true}
          name={typeName}
          openedBatch={openedBatch}
          relevantBatches={batchesName === "Collections" ? subCategory.collections : subCategory.presentations}
          setOpenedBatch={setOpenedBatch}
        />
      </div>
    </div>
  )
}
export default SubCategory
