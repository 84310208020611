import { useMutation } from "@apollo/client"
import { useContext, useState } from "react"
import { removeInvitation, removeMemberFromOrganization } from "../../graphql/mutations"
import { MainModalContext } from "../../hooks/MainModalHook"
import { ErrorPlaceholder } from "../utils/placeholders"
import { ORGANIZATION_USERS_AND_INVITEES_DATA } from "@/components/profile/Users"
import { UserDataContext } from "@/hooks/UserDataHook"

const RemoveUserForm = ({ initials, nameInModal, userId, inviteeId }) => {
  const [error, setError] = useState(false)
  const { closeModal } = useContext(MainModalContext)
  const [_removeMemberFromOrganization] = useMutation(removeMemberFromOrganization)
  const [_removeInvitation] = useMutation(removeInvitation)
  const { user } = useContext(UserDataContext)
  const [loading, setLoading] = useState(false)

  const removeMemberOrInvitation = () => {
    setLoading(true)
    if (inviteeId) {
      _removeInvitation({
        context: { isUsingNewScApi: true },
        variables: {
          id: inviteeId
        },
        refetchQueries: [
          {
            query: ORGANIZATION_USERS_AND_INVITEES_DATA,
            context: { isUsingNewScApi: true },
            variables: { _id: user.group._id }
          }
        ],
        awaitRefetchQueries: true
      })
        .then(() => {
          closeModal()
        })
        .catch(() => {
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      _removeMemberFromOrganization({
        variables: {
          userId
        },
        context: { isUsingNewScApi: true },
        refetchQueries: [
          {
            query: ORGANIZATION_USERS_AND_INVITEES_DATA,
            context: { isUsingNewScApi: true },
            variables: { _id: user.group._id }
          }
        ],
        awaitRefetchQueries: true
      })
        .then(() => {
          closeModal()
        })
        .catch(() => {
          setError(true)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {error && (
        <div className="sc-modal-member-user-remove modal-error">
          <ErrorPlaceholder
            tryAgain={() => {
              setError(false)
            }}
          />
        </div>
      )}
      <div className="sc-modal-member-user-remove" style={error ? { display: "none" } : {}}>
        {userId && (
          <div className="profile-initials-border">
            <div className="profile-initials">{initials}</div>
          </div>
        )}
        <h2 style={inviteeId ? { fontSize: 18 } : null}>
          Are you sure you want to remove <span>{nameInModal}</span> from your team?
        </h2>
        <div className="profile-buttons-ctr">
          <button className="btn btn-gray-blue" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-dark-blue" disabled={loading} onClick={removeMemberOrInvitation}>
            Confirm
          </button>
        </div>
      </div>
    </>
  )
}

export default RemoveUserForm
