import React, { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import Slide from "../../slide/Slide"
import EmptyPage from "../../EmptyPage"
import { gql, useLazyQuery } from "@apollo/client"
import useSlideLoading from "@/hooks/useSlideLoading"
import useSlideQueueManager from "@/hooks/useSlideQueueManager"
import { PlusIcon } from "@/svg/SvgIcons"

const GET_PRESENTATION = gql`
  query presentation($id: String!) {
    presentation(id: $id) {
      id
      thumbUrl
      state
      slides {
        id
        blueprintId
        state
        thumbUrl
      }
    }
  }
`

const SLIDE_DOWNLOAD_URL = gql`
  query slideDownloadUrl($slideId: String!) {
    slideDownloadUrl(slideId: $slideId)
  }
`

const BatchDetailsPage = () => {
  const location = useLocation()
  const { slides: locationSlides, batchId } = location.state
  const [slides, setSlides] = useState(locationSlides || [])
  const {
    loadingIds: addSlideLoadingIds,
    setAllSlidesLoading: setAddAllSlidesLoading,
    setSlideLoading: setAddSlideLoading
  } = useSlideLoading()
  const [isAddAllLoading, setIsAddAllLoading] = useState(false)

  const { addSlide: addSlideToQueue } = useSlideQueueManager()

  const [getPresentationData] = useLazyQuery(GET_PRESENTATION, {
    context: { isUsingNewScApi: true },
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        return
      }
      setSlides(presentation.slides)
    }
  })

  useEffect(() => {
    if (!locationSlides) {
      getPresentationData({ variables: { id: batchId } })
    }
  }, [locationSlides])

  const [getSlideDownloadUrl] = useLazyQuery(SLIDE_DOWNLOAD_URL, {
    fetchPolicy: "network-only",
    context: { isUsingNewScApi: true }
  })

  const addAllSlides = async () => {
    setIsAddAllLoading(true)
    setAddAllSlidesLoading(
      slides.map((slide) => slide.id),
      true
    )

    const addSlidePromises = slides.map((slide) =>
      addSlideToQueue(slide.id, (slideId) => getSlideDownloadUrl({ variables: { slideId } }))
        .catch((error) => {
          console.error("Error adding slide", slide.id, error)
        })
        .finally(() => {
          setAddSlideLoading(slide.id, false)
        })
    )

    await Promise.all(addSlidePromises)
    setIsAddAllLoading(false)
  }

  return (
    <div className={"flex justify-center mobile-xs:px-[20px] mb-[21px] tablet-sm:px-[60px]"}>
      <div className="w-full desktop-big:w-[1812px]">
        {slides.length > 0 ? (
          <div>
            <button
              className={`my-[15px] border rounded-[15px] px-[16px] py-[7px] flex items-center justify-center w-full bg-[#dde0e3] transition-colors ease-in-out duration-200 ${
                !isAddAllLoading ? "hover:bg-[#d5d9dd]" : ""
              }`}
              disabled={isAddAllLoading}
              onClick={addAllSlides}
            >
              <div className={`flex items-center gap-[2px] text-[14px] ${isAddAllLoading ? "opacity-50" : ""}`}>
                <span>Add all slides ({slides.length})</span>
              </div>
            </button>
            <div className={"flex flex-col gap-[15px]"} style={{ clear: "both" }}>
              {slides.map((slide, index) => {
                return (
                  <div key={slide.id}>
                    <div
                      className={
                        "grid gap-[20px] mobile-sm:grid-cols-1 w-full tablet-md:grid-cols-2 desktop-lg:grid-cols-3 desktop-2xl:grid-cols-4"
                      }
                    >
                      <Slide
                        addSlideToQueue={addSlideToQueue}
                        index={index}
                        isAddSlideLoading={addSlideLoadingIds.has(slide.id)}
                        isSearchPage
                        noActions
                        setAddSlideLoading={setAddSlideLoading}
                        slide={slide}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <EmptyPage />
        )}
      </div>
    </div>
  )
}

export default BatchDetailsPage
