import { gql, useQuery } from "@apollo/client"
import { useContext } from "react"
import { TeamEmptySvg } from "../../svg/EmptyContainerSvgs"
import { MainModalContext } from "../../hooks/MainModalHook"
import { PricingModalContext } from "../../hooks/PricingModalHook"
import { ErrorPlaceholder } from "../utils/placeholders"
import UserList from "./UserList"
import { UserDataContext } from "../../hooks/UserDataHook"

export const OrganizationMemberData = gql`
  query getOrganizationMembers {
    getOrganizationMembers {
      members {
        _id
        id
        firstName
        lastName
        email
        role
        isEditModeActive
      }
    }
  }
`

export const ORGANIZATION_USERS_AND_INVITEES_DATA = gql`
  query organization($_id: String!) {
    organization(_id: $_id) {
      users {
        _id
        id
        firstName
        lastName
        email
        role
      }
      invitees {
        id
        email
        invitationLink
      }
    }
  }
`

export default function Users() {
  // eslint-disable-next-line
  const {user} = useContext(UserDataContext)
  const { openModal } = useContext(MainModalContext)
  const { openModal: openPricingModal } = useContext(PricingModalContext)

  const { group, subscription } = user

  const { data, loading, error } = useQuery(ORGANIZATION_USERS_AND_INVITEES_DATA, {
    variables: { _id: group._id },
    context: { isUsingNewScApi: true }
  })

  if (loading) return <div className="profile-loading" />
  if (error) return <ErrorPlaceholder />

  const {
    organization: { users, invitees }
  } = data

  const handleInviteUserToTeam = () => {
    if (!subscription) {
      openPricingModal()
      return
    }

    const { teamMembers: teamMembersLimit } = subscription.plan

    if (group.groupId === "management" && users.length + invitees.length >= teamMembersLimit) {
      openPricingModal()
    } else {
      openModal({ content: "addUserToTeam" })
    }
  }

  return users.length + invitees.length > 0 ? (
    <>
      <UserList invitees={invitees} userId={user.id} users={users} />
      <div className="border-t border-solid border-[#dee2e6] mt-4 pt-4">
        <button
          className="w-[188px] bg-[#3390ff] border border-solid border-[#3390ff] rounded-[22px] text-[15px] text-white py-2 px-8  hover:bg-[#0080ea] hover:shadow-[0_0_30px_4px_rgba(60,61,63,0.25)]"
          data-testid="invite-user-btn"
          onClick={handleInviteUserToTeam}
          type="button"
        >
          Invite new user
        </button>
      </div>
    </>
  ) : (
    <>
      <div style={{ width: 220, margin: "20px auto" }}>
        <TeamEmptySvg />
      </div>
      <h2>Add new user</h2>
      <p>
        Every plan has at least two free invites for you to send to your team. To find out how many invites you have,
        check the plan you&apos;re on. You can do this by clicking on the Change Plan option on the drop down where your
        name is.
      </p>
      <div className="border-t border-solid border-[#dee2e6] mt-4 pt-4">
        <button
          className="w-[188px] bg-[#3390ff] border border-solid border-[#3390ff] rounded-[22px] text-[15px] text-white py-2 px-8  hover:bg-[#0080ea] hover:shadow-[0_0_30px_4px_rgba(60,61,63,0.25)]"
          onClick={handleInviteUserToTeam}
          type="button"
        >
          Send Invite
        </button>
      </div>
    </>
  )
}
