import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { UserDataContext } from "@/hooks/UserDataHook"
import { Slide } from "@/graphql/types/queries"
import ConfirmationModal from "@/components/modals/ConfirmationModal"
import useModal from "@/hooks/useModal"
import { useLocation } from "react-router-dom"
import { MainModalContext } from "@/hooks/MainModalHook"
import { orderSelectedSlides } from "@/utils/helpers"

interface SlideSelectBarProps {
  selectedSlideIds: string[]
  setSelectedSlideIds: Dispatch<SetStateAction<string[]>>
  allSlides: Slide[]
  refetch: () => void
  presentationId?: string
  hidden?: boolean
}

const REMOVE_SLIDES = gql`
  mutation removeSlides($slideIds: [String!]!) {
    removeSlides(slideIds: $slideIds) {
      code
      success
      message
      job {
        id
        queueName
      }
    }
  }
`
const GET_PRESENTATIONS = gql`
  query presentations($ids: [String!]!) {
    presentations(ids: $ids) {
      _id
      id
      thumbUrl
      name
      batchId
      icon
      isFavourite
      state
      sharedPresentationLinks {
        isActive
        token
        isDownloadable
        _id
      }
      slides {
        id
        slideId
        blueprintId
        thumbUrl
        name
        tags
        downloadUrl
        linksDataHeight
        linksDataWidth
        state
        isFavourite
      }
      category {
        _id
      }
    }
  }
`
const SlideSelectBar = ({
  selectedSlideIds,
  setSelectedSlideIds,
  allSlides,
  refetch,
  presentationId,
  hidden
}: SlideSelectBarProps) => {
  const [areAllSelected, setAreAllSelected] = useState(false)
  const [removeSlides] = useMutation(REMOVE_SLIDES, {
    context: { isUsingNewScApi: true }
  })
  const {
    user,
    user: { isEditModeActive, cart },
    updateSlidesInCartFunction
  } = useContext(UserDataContext)

  const [getPresentationsData, { stopPolling: stopPresentationDataPolling }] = useLazyQuery(GET_PRESENTATIONS, {
    context: { isUsingNewScApi: true },
    pollInterval: 1500,
    fetchPolicy: "network-only",
    onCompleted: ({ presentation }) => {
      if (!presentation) {
        stopPresentationDataPolling()
        return
      }
      const slides = presentation.slides
      if (!slides?.length) return
      if (presentation.state === "merged") {
        stopPresentationDataPolling()
      }
    }
  })

  const { isOpen: isModalOpen, openModal: openConfirmationModal, closeModal: closeConfirmationModal } = useModal()
  const { openModal } = useContext(MainModalContext)
  const location = useLocation()

  const presentationIds = useMemo(
    () =>
      presentationId
        ? [presentationId]
        : allSlides.reduce((ids: string[], slide) => {
            if (slide.presentations?.length && selectedSlideIds.includes(slide.id)) {
              ids.push(slide.presentations[0].id)
            }
            return ids
          }, []),
    [allSlides, selectedSlideIds, presentationId]
  )

  useEffect(() => {
    setAreAllSelected(allSlides.every((slide) => selectedSlideIds.includes(slide.id)))
  }, [selectedSlideIds, allSlides])

  const closeSelectMode = () => {
    setSelectedSlideIds([])
  }

  const handleSelectAll = () => {
    setSelectedSlideIds(allSlides.map((slide) => slide.id))
  }

  const handleAddToCart = async () => {
    const selectedSlides = allSlides.filter((slide) => selectedSlideIds.includes(slide.id))
    if (cart) {
      const items = [...(cart?.slides || []), ...selectedSlides]
      const uniqueItems = items.filter((v, i, a) => a.findIndex((t) => t.blueprintId === v.blueprintId) === i)
      await updateSlidesInCartFunction(uniqueItems)
    } else {
      await updateSlidesInCartFunction(selectedSlides)
    }
    closeSelectMode()
  }

  const handleMoveTo = () => {
    const orderedSelectedSlideIds = orderSelectedSlides(allSlides, selectedSlideIds)

    openModal({
      content: "moveOrCopySlides",
      data: { presentationIds, blueprintIds: orderedSelectedSlideIds, closeSelectMode }
    })
  }

  const handleCopyTo = () => {
    const orderedSelectedSlideIds = orderSelectedSlides(allSlides, selectedSlideIds)

    openModal({
      content: "moveOrCopySlides",
      data: {
        presentationIds,
        blueprintIds: orderedSelectedSlideIds,
        isCopyToModal: true,
        closeSelectMode
      }
    })
  }

  const handleDelete = () => {
    removeSlides({
      variables: { slideIds: selectedSlideIds },
      onCompleted: () => {
        refetch()
        getPresentationsData({ variables: { ids: presentationIds } })
        closeSelectMode()
      }
    })
    closeConfirmationModal()
  }

  const isDeleteAvailable = useMemo(() => {
    return (
      location.pathname.startsWith("/my-presentations") ||
      (isEditModeActive && (user.role === "admin" || user.role === "owner"))
    )
  }, [isEditModeActive, user.role])

  const isEditor = useMemo(() => {
    return isEditModeActive && (user.role === "admin" || user.role === "owner")
  }, [isEditModeActive, user.role])

  return (
    <div
      className={`${
        hidden ? "hidden" : ""
      } bg-white rounded-[30px] border border-[#C9C9CC] px-[25px] [&>*]:py-[10px] flex gap-[24px] justify-center ${
        isEditor
          ? "min-[743px]:min-w-[722px]"
          : isDeleteAvailable
          ? "mobile-md:min-w-[510px]"
          : "mobile-md:min-w-[412.36px]"
      } [&>button:hover]:text-sc-blue`}
      data-testid="select-bar"
    >
      <button className="flex items-center" data-testid="close-from-bar" onClick={closeSelectMode}>
        <svg className="fill-current" height={10} version="1.1" viewBox="0 0 14 14" width={10} x="0px" y="0px">
          <path
            d="M7.7,7l6.1-6.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0L7,6.3L0.9,0.1C0.7,0,0.3,0,0.1,0.1
		            S0,0.7,0.1,0.9L6.3,7l-6.1,6.1c-0.2,0.2-0.2,0.5,0,0.7C0.2,14,0.4,14,0.5,14s0.3,0,0.4-0.1L7,7.7l6.1,6.1c0.1,0.1,0.2,0.1,0.4,0.1
		            s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L7.7,7z"
          />
        </svg>
      </button>
      <div
        className="flex items-center text-center text-white bg-[#1683FB] px-[10px]"
        data-testid="selected-count-from-bar"
      >
        {selectedSlideIds.length} selected
      </div>
      <button
        className={`flex items-center gap-[5px] ${areAllSelected ? "!text-sc-light-font" : ""}`}
        data-testid="select-all-from-bar"
        disabled={areAllSelected}
        onClick={handleSelectAll}
      >
        <svg
          className="stroke-current"
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.6665 12L11.3332 14.6667" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.333 10.6667L11.333 14.6667" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Select All</span>
      </button>
      <button className="flex items-center gap-[5px]" data-testid="add-to-cart-from-bar" onClick={handleAddToCart}>
        <svg
          className="stroke-current"
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12 8.79999V15.2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.7998 12H15.1998" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>Add to cart</span>
      </button>
      {isEditor && (
        <>
          <button className="flex items-center gap-[5px]" data-testid="move-to-from-bar" onClick={handleMoveTo}>
            <svg
              className="stroke-current"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M11.7773 14.8889L14.8885 11.7778L11.7773 8.66666" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M8.6665 11.7777H14.8887" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Move to</span>
          </button>
          <button className="flex items-center gap-[5px]" data-testid="copy-to-from-bar" onClick={handleCopyTo}>
            <svg
              className="stroke-current"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.4003 9.59991H11.2001C10.3165 9.59991 9.6001 10.3163 9.6001 11.1999V18.4C9.6001 19.2836 10.3165 20 11.2001 20H18.4003C19.284 20 20.0004 19.2836 20.0004 18.4V11.1999C20.0004 10.3163 19.284 9.59991 18.4003 9.59991Z"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.40007 14.4001H5.60004C5.17569 14.4001 4.76871 14.2315 4.46864 13.9314C4.16858 13.6314 4 13.2244 4 12.8001V5.60001C4 5.17566 4.16858 4.76869 4.46864 4.46863C4.76871 4.16857 5.17569 4 5.60004 4H12.8002C13.2246 4 13.6316 4.16857 13.9316 4.46863C14.2317 4.76869 14.4003 5.17566 14.4003 5.60001V6.40002"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Copy to</span>
          </button>
        </>
      )}
      {isDeleteAvailable && (
        <>
          <button className="flex items-center gap-[5px]" data-testid="delete-from-bar" onClick={openConfirmationModal}>
            <svg
              className="stroke-current"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4.6665 7.20001H6.2665H19.0665" strokeLinecap="round" strokeLinejoin="round" />
              <path
                d="M8.6666 7.2V5.6C8.6666 5.17565 8.83517 4.76869 9.13523 4.46863C9.43529 4.16857 9.84226 4 10.2666 4H13.4666C13.8909 4 14.2979 4.16857 14.598 4.46863C14.898 4.76869 15.0666 5.17565 15.0666 5.6V7.2M17.4666 7.2V18.4C17.4666 18.8243 17.298 19.2313 16.998 19.5314C16.6979 19.8314 16.2909 20 15.8666 20H7.8666C7.44226 20 7.03529 19.8314 6.73523 19.5314C6.43517 19.2313 6.2666 18.8243 6.2666 18.4V7.2H17.4666Z"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M10.2666 11.2V16" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M13.4658 11.1999V15.9999" stroke="#6B6F7A" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Delete</span>
          </button>
          <ConfirmationModal
            close={closeConfirmationModal}
            confirmButtonText="Delete"
            confirmFunction={handleDelete}
            context={
              <>
                Are you sure you want to remove {selectedSlideIds.length} slide
                {selectedSlideIds.length !== 1 ? "s" : ""} from SlideCamp?
              </>
            }
            isOpen={isModalOpen}
            mode="danger"
            title="Delete"
          />
        </>
      )}
    </div>
  )
}

export default SlideSelectBar
